

























//@ts-ignore
import Themeable from "vuetify/lib/mixins/themeable";
import {Vue,Component,Prop} from 'vue-property-decorator';
import ItemClass from './ItemClass';
import { Children, Modulo } from "@/store/Auth/types";

@Component({
    name : 'ItemComponent',
    mixins:[Themeable]
})
export default class ItemComponent extends Vue 
{

   @Prop({type:Object})
    item!:Children;

   @Prop({type:Boolean,default:false})
   text!:boolean;

    get computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    }
    get href() {
      return this.item.to || (!this.item.to ? "#" : undefined);
    }
};
